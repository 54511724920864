import { MorseCodeCharacter } from "./MorseCodeCharacter";

export const morseCodeCharacters: MorseCodeCharacter[] = [
  { letter: "A", code: ".-", type: "letter" },
  { letter: "B", code: "-...", type: "letter" },
  { letter: "C", code: "-.-.", type: "letter" },
  { letter: "D", code: "-..", type: "letter" },
  { letter: "E", code: ".", type: "letter" },
  { letter: "F", code: "..-.", type: "letter" },
  { letter: "G", code: "--.", type: "letter" },
  { letter: "H", code: "....", type: "letter" },
  { letter: "I", code: "..", type: "letter" },
  { letter: "J", code: ".---", type: "letter" },
  { letter: "K", code: "-.-", type: "letter" },
  { letter: "L", code: ".-..", type: "letter" },
  { letter: "M", code: "--", type: "letter" },
  { letter: "N", code: "-.", type: "letter" },
  { letter: "O", code: "---", type: "letter" },
  { letter: "P", code: ".--.", type: "letter" },
  { letter: "Q", code: "--.-", type: "letter" },
  { letter: "R", code: ".-.", type: "letter" },
  { letter: "S", code: "...", type: "letter" },
  { letter: "T", code: "-", type: "letter" },
  { letter: "U", code: "..-", type: "letter" },
  { letter: "V", code: "...-", type: "letter" },
  { letter: "W", code: ".--", type: "letter" },
  { letter: "X", code: "-..-", type: "letter" },
  { letter: "Y", code: "-.--", type: "letter" },
  { letter: "Z", code: "--..", type: "letter" },
  { letter: "Å", code: ".--.-", type: "letter" },
  { letter: "Ä", code: ".-.-", type: "letter" },
  { letter: "Ö", code: "---.", type: "letter" },
  { letter: "1", code: ".----", type: "number" },
  { letter: "2", code: "..---", type: "number" },
  { letter: "3", code: "...--", type: "number" },
  { letter: "4", code: "....-", type: "number" },
  { letter: "5", code: ".....", type: "number" },
  { letter: "6", code: "-....", type: "number" },
  { letter: "7", code: "--...", type: "number" },
  { letter: "8", code: "---..", type: "number" },
  { letter: "9", code: "----.", type: "number" },
  { letter: "0", code: "-----", type: "number" },
  { letter: ".", code: ".-.-.-", type: "punctuation" },
  { letter: ",", code: "--..--", type: "punctuation" },
  { letter: "?", code: "..--..", type: "punctuation" },
  { letter: "'", code: ".----.", type: "punctuation" },
  { letter: "!", code: "-.-.--", type: "punctuation" },
  { letter: "/", code: "-..-.", type: "punctuation" },
  { letter: "(", code: "-.--.", type: "punctuation" },
  { letter: ")", code: "-.--.-", type: "punctuation" },
  { letter: "&", code: ".-...", type: "punctuation" },
  { letter: ":", code: "---...", type: "punctuation" },
  { letter: ";", code: "-.-.-.", type: "punctuation" },
  { letter: "=", code: "-...-", type: "punctuation" },
  { letter: "+", code: ".-.-.", type: "punctuation" },
  { letter: "-", code: "-....-", type: "punctuation" },
  { letter: "_", code: "..--.-", type: "punctuation" },
  { letter: '"', code: ".-..-.", type: "punctuation" },
  { letter: "$", code: "...-..-", type: "punctuation" },
  { letter: "@", code: ".--.-.", type: "punctuation" },
];
